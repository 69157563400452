
.pubDisplay {
  padding-left: 5vw;
  padding-right: 5vw;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5vw;

  .pub:nth-child(3n + 1) {
    grid-column: 1;
  }

  .pub:nth-child(3n + 2) {
    grid-column: 2;
  }

  .pub:nth-child(3n + 3) {
    grid-column: 3;
  }

  .pub {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: transform 0.5s ease;
    position:relative;
    width: 26vw;
    text-decoration: none;
    margin-bottom: 5vh;

    a {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }

    img {
      height: 75%;
      width: 100%;
    }

    text{
      text-align: center;
      font-size: 2vw;
      color: black;
      background-color: aquamarine;
      font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      height: 25%;
      display: flex;
      object-fit: fill;
      justify-content: center;
      align-items: center;
    }

    &:hover {
      transform: scale(1.02);
      animation: glow 1s ease-in-out infinite alternate; /* Start the glow animation on hover */
    }
  }
}

@keyframes glow {
  0% {
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5); /* Initial shadow with 50% opacity */
  }
  100% {
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.9); /* Stronger glow with 90% opacity */
  }
}
