
.nav-bar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
}
.nav-bar a, FontAwesomeIcon {
  text-decoration: none;
  color: white;
  font-size: 20px;
  margin-right:5vw;
  position: relative; 
}

@media (min-width: 737px) {
  .nav-bar {
    margin-top: 2vh;
    margin-bottom: 5vh;
  }
}
@media (max-width: 737px) {
  .nav-bar {
    margin-top: 1vh;
    margin-bottom: 3vh;
  }
}


.nav-bar a:hover::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    /* transform: translate(-60%, -200%); */
    border-radius: 120%;
    box-shadow: 0 0 0 0 rgba(255, 248, 248, 0.7); /* Initial shadow style */
    opacity: 0; /* Hide initially */
    transition: opacity 0.3s ease; /* Smooth transition for opacity */
    opacity: 1; /* Show the pulsating effect on hover */
    animation: pulse 1.2s infinite alternate; /* Use the 'pulse' animation on hover */
}

@keyframes pulse {
    from {
        box-shadow: 0 0 0 0 rgb(245, 242, 242); /* Start with a dim shadow */
    }
    to {
        box-shadow: 0 0 20px 20px rgba(255, 255, 255, 0); /* Expand and fade the shadow on alternate */
    }
}