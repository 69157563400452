

.my-headshot {
    width: 17.2vw; 
    /* max-width: 300px; Set a maximum width if desired */
    height: 17.2vw;
    border-radius: 50%; 
    border: 2px solid #808080; /* Gray border with 2px width */
    overflow: hidden; /* Hide overflow */
}  

.my-headshot img {
    height : 120%; 
    width: 200%;
    object-fit: cover; /* this allows for the image to scale outside the container boarder*/
}

.medium-link {
    width: 8.6vw; 
    /* max-width: 300px; Set a maximum width if desired */
    height: 8.6vw;
    padding: 1vw;
    border-radius: 50%; 
    border: 2px solid #808080; /* Gray border with 2px width */ 
}  


@media (min-width: 737px) {
  .icon-row-1-icon-2 {
    margin-top: 5vh;
  }
  .small-link {
    width: 5.6vw; 
    /* max-width: 300px; Set a maximum width if desired */
    height: 5.6vw;
  }
  .medium-link {
    width: 8.6vw; 
    /* max-width: 300px; Set a maximum width if desired */
    height: 8.6vw;
  }

  .text-row-three text{
    margin-top:5%;
    font-size: 1.6vw;
  }
  .text-row-four text{
    font-size: 1.4vw;
  }
  .header-col { 
    visibility: hidden;
    position:absolute;
  }
}

@media(max-width: 737px) {
  .icon-row-1-icon-2 {
    margin-top:5px;
  }
  .my-headshot {
    margin-top:2vh;
    width: 25vw; 
    /* max-width: 300px; Set a maximum width if desired */
    height: 25vw;
  } 
  .small-link {
    width: 10vw; 
    /* max-width: 300px; Set a maximum width if desired */
    height: 10vw;
  }
  .medium-link {
    width: 15vw; 
    /* max-width: 300px; Set a maximum width if desired */
    height: 15vw;
  }
  .text-row-one{
    visibility:hidden;
    position:absolute;
  }
  .text-row-two{
    visibility: hidden;
    position:absolute
  }
  .text-row-three text{
    margin-top: 5vh;
    font-size: 3vw;
  }

  .text-row-four text{
    font-size: 3vw;
  }
}

.header-phone-1 text{
  margin-top: 4vh;
  font-size: 4vw;
  font-family: 'Playfair Display', serif;
  color:white;
  text-align: center;
}

.header-phone-2 text{
    margin-top:2vh;
    font-size: 2.3vw;
    font-family: 'Playfair Display', serif;
    color: white;
    text-align: center;
}
.header-phone-2 a {
  font-weight:bold;
  text-decoration: none;
  color: rgb(43, 123, 43);
}

.small-link {
    display: flex;
    flex-direction: row;
    padding: 1vw;
    border-radius: 50%; 
    border: 2px solid #808080; /* Gray border with 2px width */
    justify-content: center;
    overflow: hidden; /* Hide overflow */
}  

.GitLab-Icon{
    width: 100%;
    height: 100%;
    cursor: pointer;
    color: orange;
}

.Ieee-Icon{
    width: 100%;
    height: auto;
    cursor: pointer;
    color: blue;
}

.LinkedIn-Icon{
    width: 100%;
    height: auto;
    cursor: pointer;
    color: #0077B5;
}

.github-icon{
  width: 100%;
  height: auto;
  cursor: pointer;
  color: #ffffff;
}

.medium-icon{
  width: 80%;
  height: 80%;
  cursor: pointer;
  color: #000000;
}

.icon-row-2-icon-1{
  display: flex;
  background-color: #808080; /* Gray border with 2px width */ 
  justify-content: center;
  align-items: center;
}  

/* .text-row-one {
  margin-top: 40vh;
} */

.text-row-one text{
  font-size: 5vw;
  font-family: 'Playfair Display', serif;
  color:white;
  text-align: center;
}
.text-row-two a {
  font-weight:bold;
  text-decoration: none;
  color: rgb(43, 123, 43);
}

.text-row-two text{
    margin-top:5%;
    font-size: 2.3vw;
    font-family: 'Playfair Display', serif;
    color: white;
    text-align: center;
}

.text-row-three text{
    font-family: 'Playfair Display', serif;
    color: white;
    text-align: center;
}

.text-row-four text{
    margin-top:5%;
    font-family: 'Playfair Display', serif;
    color: white;
    text-align: center;
}


body {
    /* margin: 0;
    margin-left: 10px;
    padding: 0; */
    background-color: #000000; /* Set the background color of the entire page */
    color: white; 
    /* font-family: Arial, sans-serif; */
}

